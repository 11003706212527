.menu{
  min-height: 700px;
  width: 240px
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

/*
 * Sidebar
 */

.sidebar {

  /* border-left: 1px solid gray; */
  position: fixed;
  top: 30px;
  bottom: 0;
  right: 0;
  z-index: 100;
  padding: 0;
  box-shadow:   -1px 0px 0px 0px #8080800a;
}
.sidebar-sticky {
  /* position: -webkit-sticky; */
  /* position: sticky; */
  /* top: 48px;  */
  height: calc(100vh - 48px);
  /* padding-top: .5rem; */
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
}

.sidebar .nav-link {
  font-weight: 100;
  color: black;
  float: right;
  direction: rtl;
  transition: transform 0.5s;
}
.sidebar .nav-link:hover {
  font-weight: 300;
  color: purple !important;
  float: right;
  margin-right: 2px;
  direction: rtl;
  transform : scale(1.2);
}
.sidebar img {
  transition: transform 1s;}

.sidebar img:hover {
  -ms-transform: scale(1.1); /* IE 9 */
   -webkit-transform: scale(1.1); /* Safari 3-8 */
   transform: scale(1.1);
}
.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}



.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */


.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }
.pro-sidebar {
    color: #010a00;
    height: 100%;
    width: 270px;
    min-width: 270px;
    text-align: left;
    transition: width, left, right, 0.3s;
    position: relative;
    z-index: 1009;
}
.pro-sidebar .pro-menu.shaped .pro-menu-item > .pro-inner-item > .pro-icon-wrapper {
    background-color: #ffffff;
}
.pro-sidebar .pro-menu .pro-menu-item > .pro-inner-item:hover {
    color: #24dd28;
}
.pro-sidebar .pro-menu .pro-menu-item {
    font-size: 20px;
}
.badge-menu{
  border-color: green!important
}
