@media (max-width : 575px){
  .nav-item{
    margin-bottom: 15px;
    margin-right: 50px
  }
}

.lh-xl li{
  line-height: 1rem !important
}
body{
  font-family: 'Yekan';
  overflow :visible !important;
  /* padding-right: 17px */
  background-color: #ffffff00 !important;
}
.brand{
  font-family: cursive !important;
  text-shadow: 1px 1px 3px white;
}
.text-small{
  font-size: 10px;
  cursor: pointer;
}
.ltr{
  text-align: left;
  direction: ltr;
}
.dropzone p,li{
  font-size: 14px
}
.dropzone{
  padding: 25px;
  border: 1px dotted black;
  border-radius: 20px
}
.rtl{
  text-align: right;
  direction: rtl;
}
.w50{
  width: 50px;
  white-space: no-wrap;
  text-overflow: ellipsis;
  overflow:hidden
}
.w70{
  width: 70px;
  white-space: no-wrap;
  text-overflow: ellipsis;
  overflow:hidden
}
.w100{
  width: 100px;
  white-space: no-wrap;
  text-overflow: ellipsis;
  overflow:hidden
}
.homepage-section-1{

}
.w150{
  min-width: 149px;
  max-width: 150px;
  white-space :nowrap;
  text-align: left;
  direction: ltr;
  text-overflow :ellipsis;
  overflow:hidden
}
.slide-title{
  color: white;
  padding-top: 80px;
  text-shadow: 0px 7px 73px #ffffff;
}
.slide h3{
  padding-top: 100px;
  color: white;

}
.slide::after{
  content: '';
    background-color: #212529d6;
    background-blend-mode: color-burn;
    height: 600px;
    display: block;

    width: 100%;
    margin-top: -600px;
}
.slide-1{
  height: 600px;
  background: url('./slide1.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slide-2{
  height: 600px;
  background: url('./slide2.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.slide span::after{
  content: '';
  width: 5px;
  height: 5px;
  border-radius: 50%;
  color: green;
  direction: rtl;
  display: block;
  position: absolute;
}
.slide-3{
  height: 600px;
  background: url('./slide3.jpg');
  background-size: cover;
  background-repeat: no-repeat;
  background-position: center;
}
.polygonpath{
  clip-path: polygon(33% 0, 20% 50%, 33% 100%, 11% 100%, 0% 50%, 13% 0);
  width: 100%;
  background-color: black
}
.description{
  font-family: 'Yekan' !important
}
.second-title{
  font-family: 'Yekan' !important
}
.footer{
  padding: 50px 10px 10px 10px;
  background-color: #fafafa;
  border-top: 2px solid #fafae5
}
.resources{
  font-family: 'Yekan' !important
}
.homeding{
  background: url('../images/wp6871230.jpg') no-repeat 50% fixed;
  background-size: cover;
  position: fixed;
  width: 100%;
  height: 100%;
  z-index: -99999;
}
.p-v-value{
  background-color: black;
  width: 50px;
  height: 50px;
    padding: 15px 0 0 0;
    text-align: center;
    color: white;
    font-size: 14px;
    border-radius: 55px;
    cursor: pointer;
}
.carousel-indicators{
  display: none;
}
.slider{

  max-width: 100%;
  height: 500px;
  /* background-color: none; */
}
.slider img{
  width: 100% !important
}
.modal-open{
  overflow: visible !important;
  padding-right: 0px !important
}
.bg-transparant{
  background-color:#000000b0 !important
}
.text-landing{
  font-size: 52px;
 background: -webkit-linear-gradient(#0922dc, #69c3d5);
 -webkit-background-clip: text;
 -webkit-text-fill-color: transparent;
 font-weight: bolder;
}
.section-2-img{
  min-height: 400px;
  max-height:  max-content;
}
.bg-transparant-l1{
  background-color:#c9c5c59c !important
}
.main-block-xl-light{
  padding: 20px 50px 20px 50px;
    background-color: #f5f5dc17;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    font-weight: bolder;
    transition: transform 2s;
}
.main-block-xl-light:hover{
  padding: 20px 50px 20px 50px;
    background-color: #f5f5dc17;
    border: 1px solid white;
    border-radius: 30px;
    color: white;
    font-weight: bolder;
    -ms-transform: scale(1.05); /* IE 9 */
     -webkit-transform: scale(1.05); /* Safari 3-8 */
     transform: scale(1.05);
}
.main-block-xl-light:active{
  padding: 20px 50px 20px 50px;
    background-color: #f5f5dc17 !important;
    border: 1px solid white  !important;
    border-radius: 30px;
    color: white;
    box-shadow: none !important;
    font-weight: bolder;
}

.landing-img-s1{
  /* content: '';
  clip-path: polygon(40% 0, 28% 51%, 40% 100%, 10% 100%, 0% 50%, 13% 0); */

  background: url('../images/p/26663740.png') no-repeat ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
      width: 100%;
      height: 670px
}
.landing-img-itunes{
  background: url('../images/p/itunes-png.png') no-repeat ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.landing-img-googleplay{
  background: url('../images/p/googleplay-png.png') no-repeat ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%
}
.landing-img-game{
  background: url('../images/p/gameconsole.png') no-repeat ;
    background-position: center;
    background-repeat: no-repeat;
    background-size: contain;
    position: relative;
    width: 100%
}
.bg-primary-gradient{
  font-size: 52px;
 background: -webkit-linear-gradient(#0922dc, #69c3d5);


}
.home-transparant{
  background-color:#00000099 !important
}
.bg-image{
  background: url('../images/bg-world.jpg') no-repeat 50% fixed;
    /* background-repeat: no-repeat; */
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -99999;
}
.bg-image-show{
  background: url('../images/bg-world.jpg') no-repeat 50% fixed;
    /* background-repeat: no-repeat; */
    background-size: cover;
    position: fixed;
    width: 100%;
    height: 100%;
    z-index: -99999;
}
.itunes{
  background: url('../images/p/itunes.png') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
      width: 100%
}
.google{
  background: url('../images/p/google.jpg') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
      width: 100%

}
.xbox{
  background: url('../images/p/xbox.jpg') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
      width: 100%
}
.ps{
  background: url('../images/p/ps.jpg') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
      width: 100%
}
.perfect{
  background: url('../images/p/pm.png') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
      width: 100%
}
.ecopayz{
  background: url('../images/p/ecopayz.png') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.webmoney{
  background: url('../images/p/wb.jpg') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */
    background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.ultimate{
  background: url('../images/p/up.png') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.mint{
  background: url('../images/p/mint.jpg') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.netflix{
  background: url('../images/p/nf.png') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.spotify{
  background: url('../images/p/sf.png') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.amazon{
  background: url('../images/p/amazon.jpg') no-repeat ;
  background-color: #cccccc;
    /* height: 500px; */background-position: center;
    background-repeat: no-repeat;
    background-size: cover;
    position: relative;
    width: 100%
}
.p-text-card{
  color: white;
  background-color: #00000047;
  width: 80%;
  border-radius: 8px
}
.carousel-caption{
  top :25%
}
.rtl{
  text-align: right;
  direction: rtl;
}
a{
  text-decoration: none
}

.product-card{
cursor:pointer;
 transition: transform 2s;
 /* height: 140px; */
}
.product-card:hover{
  color :purple;
  -ms-transform: scale(1.05); /* IE 9 */
   -webkit-transform: scale(1.05); /* Safari 3-8 */
   transform: scale(1.05);
}
a:hover{
  /* color :purple !important; */
  /* margin-right: 0px; */
  text-decoration: none !important;

}
.fade {
  -webkit-transform: scale(1) rotate(
360deg);
-moz-transform: scale(1) rotate(0deg);
-ms-transform: scale(1) rotate(0deg);
transform: scale(1) rotate(
360deg);
opacity: 1;
}
.divider-menu{
  margin: 10px 0 10px 5px !important;
  /* padding: 10px 0 10px 0; */
  width: 90%;
  right: 0 !important;

}
.shop{

}
.navbar .nav-item{
  margin-right: 10px;
  margin-left: 15px;

}
.row {
    display: flex;
    flex-wrap: wrap;
    margin-right: 0px;
    margin-left: 0px;
}
.text-main{
  color : #033564 !important;
  font-size: 55px;
  letter-spacing: -1.2px;
}
.bg-auth a{
  color: white !important
}
.home-bg-transparant{
  background-color: #c3a4a47d;
}
.col-about{
  width: 60%
}
.stay-connected-title{
  /* display: none; */
}
.auth-wrapper{
  margin-top: 25px;
  min-height: 300px;
  max-height: max-content
}
.justify{
  justify-content:space-around;
}
.bg-transparent-auth{
  background-color: #c3a4a47d;
}
@media(min-width: 767px){
  .navbar-light .navbar-text {
      color: black !important;
  }
  .panel-wrapper{

    padding-right: 245px
  }
.home-bg-transparant{
  background-color: #c3a4a400 !important;
}
.mobile{
  display: none
}
}
@media(max-width: 767px){

.home{
  display: none;
}
.bg-image{
  display: none
}
.panel{
  margin-top: 10px!important
}
.sidebar{
  display: none
}
.panel-wrapper{

  padding-right: 0px !important
}
.navbar-light .navbar-brand{
    color: #44cd2b;
    font-size: 44px;
}

.navbar-light .navbar-toggler {
    color: rgba(0, 0, 0, 0.55);
    border-color: rgb(67 199 43);
    background-color: rgb(67 199 43)
}
.navbar-light .navbar-nav .nav-link {
    color: rgb(88 233 61);
}
.navbar-light .navbar-nav .nav-link:hover, .navbar-light .navbar-nav .nav-link:focus {
  color: #00ff05;
font-weight: 600;
text-decoration-line: underline;

}
.navbar-light .navbar-brand:hover, .navbar-light .navbar-brand:focus {
    color: #00ff05;
}
.navbar-light .navbar-text {
    color: rgb(192 233 16);
}
.dropdown-divider {
    height: 0;
    margin: 0.5rem 0;
    /* overflow: hidden; */
    border-top: 1px solid rgb(219 192 192 / 53%);
}
}
.bg-auth{
  background: url('../images/ybg.jpg') no-repeat 100%  ;
  background-size:cover;
  width: 100%;
  height: 100%;
  position: fixed;
}
.resources{
  color: white !important
}

.panel{
  margin-top: 70px
}
a{
  color: black !important
}
.card{
  border-radius: 20px;
}
.card-header{
  border-top-left-radius: 20px !important;
  border-top-right-radius: 20px !important;

}
.panel-bg{
  background: url('../images/bgp.jpg') no-repeat 100% fixed;
  background-size:cover;
  width: 100%;
  height: 100%;
  position: fixed;
  z-index: -99999
}
.navbar-dark{
  background-color: #ffffff00 ;
  color: white !important
}
.navbar-dark a:hover{
  color: white !important;
  transform:  none !important
}
.navbar-dark a{
  color: white !important;
  transform:  none !important
}
